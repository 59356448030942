import { Injectable } from "@angular/core";

import { UrlPrefixes } from "../models/appData";

@Injectable()
export class UrlFormatterService {
  public formatUrl(url: string): string {
    url = url.replace(" ", "");

    UrlPrefixes.map((prefix) => {
      url = url.replace(prefix, "");
    });

    return url;
  }
}
